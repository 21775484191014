import React, { useState, useEffect } from 'react';
import { useIntl, defineMessages } from 'react-intl-next';

import { Flex, Stack, Text, xcss, Pressable } from '@atlaskit/primitives';
import CheckIcon from '@atlaskit/icon/glyph/check';
import LinkIcon from '@atlaskit/icon/glyph/link';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { useCopyContentLink, PAGE_MODE } from '@confluence/share/entry-points/useCopyContentLink';
import { useIsEditorPage } from '@confluence/route-manager/entry-points/useIsEditorPage';

import { useTeammatePresenceState } from './useTeammatePresenceStore';

const i18n = defineMessages({
	viewHeader: {
		id: 'experiment-teammate-presence.empty-state.view.header',
		defaultMessage: 'You’re the only one viewing this page. Copy this link to share with others.',
		description: 'Header text in empty state popup for view mode',
	},
	editHeader: {
		id: 'experiment-teammate-presence.empty-state.edit.header',
		defaultMessage: 'You’re the only one editing this page. Copy this link to share with others.',
		description: 'Header text in empty state popup for edit mode',
	},
	copyPageLink: {
		id: 'experiment-teammate-presence.empty-state.copy.page.link',
		defaultMessage: 'Copy link',
		description: 'Copy link text',
	},
	linkCopied: {
		id: 'experiment-teammate-presence.empty-state.link.copied',
		defaultMessage: 'Link copied',
		description: 'Link copied text',
	},
});

const containerStyles = xcss({
	width: '275px',
	padding: 'space.200',
	display: 'flex',
	flexDirection: 'column',
	borderRadius: 'border.radius.100',
});

const pressableLinkStyles = xcss({
	backgroundColor: 'color.background.neutral.subtle',
	display: 'flex',
	alignItems: 'center',
	color: 'color.link',
	gap: 'space.050',
	textDecoration: 'none',
	':active': {
		color: 'color.link.pressed',
	},

	':hover': {
		textDecoration: 'underline',
	},
});

export const TeammatePresenceNoCollaboratorsPopup = () => {
	const [copiedPageLink, setCopiedPageLink] = useState<boolean>(false);
	const [_, setIsCopyError] = useState<Error | undefined>(undefined); // TODO consume the error variable when content is ready
	const { formatMessage } = useIntl();
	const { contentId, contentType, spaceKey } = useTeammatePresenceState();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const isEditPage = useIsEditorPage();

	const { copyContentLink } = useCopyContentLink({
		pageMode: isEditPage ? PAGE_MODE.EDIT : PAGE_MODE.VIEW,
		contentType,
		contentId,
		spaceKey,
		source: 'TeammatePresence - emptyState',
	});

	const headerText = isEditPage ? formatMessage(i18n.editHeader) : formatMessage(i18n.viewHeader);

	const handleCopyPageLinkClick = async () => {
		try {
			await copyContentLink();
			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					action: 'clicked',
					actionSubject: 'button',
					actionSubjectId: 'noPresencePopupCopyLink',
					source: 'presence',
					attributes: {
						isEditPage,
					},
				},
			}).fire();
			setCopiedPageLink(true);
		} catch (e) {
			setIsCopyError(e); // TODO handle error with content update when content is ready
		}
	};

	useEffect(() => {
		if (copiedPageLink) {
			const timeout = setTimeout(() => {
				setCopiedPageLink(false);
			}, 4000);

			return () => clearTimeout(timeout);
		}
	}, [copiedPageLink]);

	useEffect(() => {
		createAnalyticsEvent({
			type: 'sendScreenEvent',
			data: {
				name: 'presenceCopyPagePopup',
				attributes: {
					isEditPage,
				},
			},
		}).fire();
	}, [createAnalyticsEvent, isEditPage]);

	const [icon, message] = copiedPageLink
		? [<CheckIcon label="" size="small" key="check-icon" />, formatMessage(i18n.linkCopied)]
		: [<LinkIcon label="" size="small" key="link-icon" />, formatMessage(i18n.copyPageLink)];

	return (
		<Stack xcss={containerStyles} space="space.150">
			<Text as="span">{headerText}</Text>
			<Flex direction="row" justifyContent="end">
				<Pressable xcss={pressableLinkStyles} onClick={handleCopyPageLinkClick}>
					{icon}
					<span>{message}</span>
				</Pressable>
			</Flex>
		</Stack>
	);
};
