import React, { useCallback, useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { MenuGroup, Section } from '@atlaskit/menu';
import { Box, xcss } from '@atlaskit/primitives';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { useIsEditorPage } from '@confluence/route-manager/entry-points/useIsEditorPage';
import { useSessionData } from '@confluence/session-data';

import { TeammatePresencePageAnalyticsItem } from './TeammatePresencePageAnalyticsItem';
import { TeammatePresenceMenuViewItem } from './TeammatePresenceMenuViewItem';
import { TeammatePresenceMenuEditItem } from './TeammatePresenceMenuEditItem';
import type { PresenceAvatarProps } from './presenceTypes';

const i18n = defineMessages({
	activeNowTitle: {
		id: 'experiment-teammate-presence.menu.viewing.title',
		defaultMessage:
			'{count, plural, =0 {Active now ({count})} one {Active now ({count})} other {Active now ({count})}}',
		description:
			'The text is shown as a title of a section inside of a menu. It describes the number of users actively on the page. {count} will be a literal number, like 0, 1, 2, 3, etc...',
	},
	commenting: {
		id: 'experiment-teammate-presence.menu.commenting',
		defaultMessage: 'Commenting',
		description:
			"This text is shown to describe a user's current action. The person is currently commenting on the page",
	},
	you: {
		id: 'experiment-teammate-presence.menu.you',
		defaultMessage: 'You',
		description: 'This text is shown to indicate the user is you. You are the user',
	},
});

const popupMenuContainerStyles = xcss({
	backgroundColor: 'elevation.surface.overlay',
	boxShadow: 'elevation.shadow.overlay',
	borderRadius: 'border.radius',
	position: 'relative',
});

type TeammatePresenceMenuProps = {
	users: PresenceAvatarProps[];
};

export const TeammatePresenceMenu = ({ users }: TeammatePresenceMenuProps) => {
	const { formatMessage } = useIntl();
	const isEditPage = useIsEditorPage();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { userId } = useSessionData();

	useEffect(() => {
		createAnalyticsEvent({
			type: 'sendScreenEvent',
			data: {
				name: 'presenceMenu',
				attributes: {
					isEditPage,
				},
			},
		}).fire();
	}, [createAnalyticsEvent, isEditPage]);

	const getMenuItems = useCallback(() => {
		const originalOrdering: { [key: string]: number } = {};
		users.forEach((user, index) => (originalOrdering[user.sessionId] = index));
		return users
			.filter((user) => user.activity)
			.sort((a, b) => {
				const [i, j] = isEditPage ? [a, b] : [b, a]; // This just reverses the sort order. In the view mode we want "Viewers" to appear verse and vice-versa.
				const [aPosition, bPosition] = [
					originalOrdering[a.sessionId],
					originalOrdering[b.sessionId],
				];
				let positionComparison = 0;
				if (aPosition < bPosition) positionComparison = -1;
				else if (aPosition > bPosition) positionComparison = 1;

				let myselfComparison = 0;
				if (a.userId === userId) myselfComparison = -1;
				else if (b.userId === userId) myselfComparison = 1;

				return i.activity!.localeCompare(j.activity!) || myselfComparison || positionComparison;
			})
			.map((user, index) => {
				const { activity } = user;
				const isView = isEditPage ? 'edit' : 'view';
				const MenuItem =
					activity === 'VIEWING' ? TeammatePresenceMenuViewItem : TeammatePresenceMenuEditItem;

				return <MenuItem key={`teammate-presence-${isView}-${index}`} {...user} />;
			});
	}, [users, isEditPage, userId]);

	return (
		<Box xcss={popupMenuContainerStyles}>
			<MenuGroup maxHeight={414} minWidth={360} maxWidth={360}>
				<Section isScrollable title={formatMessage(i18n.activeNowTitle, { count: users.length })}>
					{getMenuItems()}
				</Section>
				<TeammatePresencePageAnalyticsItem />
			</MenuGroup>
		</Box>
	);
};
