import { PAGE_ANALYTICS } from '@confluence/named-routes';

import type { ContentAnalyticsLinkProps } from './ContentAnalyticsLink';

type GetContentAnalyticsLinkProps = Omit<ContentAnalyticsLinkProps, 'children' | 'testId'> & {
	spaceKey?: string;
};

type GetContentAnalyticsLinkReturnType = string | null;

export const getContentAnalyticsLink = ({
	contentId,
	contentTab,
	contentType,
	spaceKey,
}: GetContentAnalyticsLinkProps): GetContentAnalyticsLinkReturnType => {
	if (!contentId || !contentType || !spaceKey) {
		return null;
	}
	// TODO - CCIR-11: Remove the following comment once the analytics migration is complete:
	// PAGE_ANALYTICS route code will redirect to the correct URL based on the feature gate and
	// FedRAMP environment
	const routeUrl = PAGE_ANALYTICS.toUrl({ spaceKey, contentId });

	// We still need the additional URL query params to be passed to the old Analytics plugin
	// TODO - CCIR-11: Remove the additonal query params once the native Analytics UI migration is
	// complete
	const queryParams = new URLSearchParams({
		'content.id': contentId,
		'content.type': contentType,
	});

	if (contentTab) {
		queryParams.append('ac.view', contentTab);
	}

	return `${routeUrl}?${queryParams}`;
};
