import React, { useState, useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';
import uuid from 'uuid';

import AvatarGroup from '@atlaskit/avatar-group';
import type { AvatarGroupProps } from '@atlaskit/avatar-group';
import { Pressable, Box, Flex, xcss } from '@atlaskit/primitives';
import Popup from '@atlaskit/popup';
import type {
	CollabActivityData,
	CollabEventPresenceData,
	CollabEditOptions,
} from '@atlaskit/editor-common/collab';
import Tooltip from '@atlaskit/tooltip';
import { token } from '@atlaskit/tokens';

import { useNativeCollabState } from '@confluence/native-collab';
import { useIsEditorPage } from '@confluence/route-manager/entry-points/useIsEditorPage';
import { useSessionData } from '@confluence/session-data';
import { InviteToEditButton } from '@confluence/editor-presence-avatar-group';
import { fg } from '@confluence/feature-gating';

import { TeammatePresenceMenu } from './TeammatePresenceMenu';
import { TeammatePresenceNoCollaboratorsContainer } from './TeammatePresenceNoCollaboratorsContainer';
import type { Participant } from './useTeammatePresenceStore';
import { useTeammatePresenceActions, useTeammatePresenceState } from './useTeammatePresenceStore';
import {
	generateAvatarDescriptorFromParticipant,
	getBorderColorFromSessionId,
} from './presenceUtils';
import type { PresenceAvatarProps } from './presenceTypes';

const MAX_AVATARS = 4;
const i18n = defineMessages({
	userIsEditing: {
		id: 'experiment-teammate-presence.menu.user-is-editing',
		defaultMessage: '{name} is editing',
		description:
			'This text is shown as a tooltip to indicate that {name} is editing the page. {name} would be a different users display name',
	},
	userIsViewing: {
		id: 'experiment-teammate-presence.menu.user-is-viewing',
		defaultMessage: '{name} is viewing',
		description:
			'This text is shown as a tooltip to indicate that {name} is viewing the page. {name} would be a different users display name',
	},
	youAreEditing: {
		id: 'experiment-teammate-presence.menu.you-are-editing',
		defaultMessage: 'You are editing',
		description: 'This text is shown as a tooltip to indicate that you are editing the page.',
	},
	youAreViewing: {
		id: 'experiment-teammate-presence.menu.you-are-viewing',
		defaultMessage: 'You are viewing',
		description: 'This text is shown as a tooltip to indicate that you are viewing the page.',
	},
});

const pressableStyles = xcss({
	cursor: 'pointer',
	display: 'flex',
	backgroundColor: 'color.background.neutral.subtle',
	alignItems: 'center',
	height: '100%',
	paddingTop: 'space.0',
	paddingBottom: 'space.0',
	paddingLeft: 'space.0',
	paddingRight: 'space.0',
});

const imageContainerStyles = xcss({
	height: '32px',
	position: 'relative',
	marginRight: 'space.075',
});

const noPointerEvents = xcss({
	pointerEvents: 'none',
});

const dividerStyles = xcss({
	borderLeftColor: 'color.border',
	borderLeftWidth: '2px',
	borderLeftStyle: 'solid',
	display: 'inline-block',
	width: '1px',
	height: '16px',
	marginLeft: 'space.075',
	marginRight: 'space.075',
});

/**
 * `USER_LIMIT` is used to manage the maximum number of participants in a collaborative editing session
 * to ensure optimal performance and a clean user interface.
 *
 * - Handling in Editing Page: On editing pages, the component behaves differently when the `USER_LIMIT` is exceeded:
 *   - It marks the session as having reached the limit using `setHasReachedLimit(true)` but does not terminate the editing session
 *     (`collabEditProvider.destroy()` is not called). This is to ensure that ongoing editing activities are not interrupted.
 *   - The primary goal is to maintain the editing session's integrity and user experience, even when the limit is reached, by
 *     the provider will continue to function without disrupting the editing experience. Editor side will set the limit.
 * - The limit is enforced through event handlers (`presence`, `activity:join`, and `activity:ack`), which manage
 *   the session's participants based on their activities (viewing or editing) and the session's overall participant count.
 */
const USER_LIMIT = 100;

export const TeammatePresenceAvatarGroup = ({
	collabEditOption,
}: {
	collabEditOption?: CollabEditOptions;
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const [{ collabEditProvider }] = useNativeCollabState();
	const [avatarDescriptors, setAvatarDescriptors] = useState<PresenceAvatarProps[]>([]);
	const isEditPage = useIsEditorPage();
	const { addOrUpdateSingleParticipant, removeParticipant, getParticipantsCount } =
		useTeammatePresenceActions();
	const { participantsMap: currentParticipants } = useTeammatePresenceState();
	const { userId } = useSessionData();
	const { formatMessage } = useIntl();
	const [userSuggestionQuerySessionId] = useState(uuid());

	useEffect(() => {
		if (!collabEditProvider) {
			return;
		}

		/**
		 * A Handler for event "activity:ack" forwarded from NCS.
		 * This event "activity:ack" is emitted by the existing collaborators in response to a new collaborator
		 * joining. It is used to inform the new collaborator about the current actions or states of the existing
		 * collaborators, such as viewing or editing.
		 */
		const handleActivityAck = (data: CollabActivityData) => {
			if (!collabEditProvider) {
				return;
			}
			collabEditProvider.getParticipants().map((participant) => {
				if (
					participant.sessionId === data.sessionId &&
					participant.sessionId !== collabEditProvider?.getSessionId()
				) {
					const newParticipant = { ...participant, activity: data.activity };
					addOrUpdateSingleParticipant(newParticipant);
				}
			});
		};

		/**
		 * A Handler for event "activity:join" forwarded from NCS.
		 * Existing participant respond to the new joiner with their status.
		 * This event "activity:join" is emitted when a new collaborator joins the session. The event carries
		 * information about the action the new collaborator is currently doing in the session, such as viewing
		 * or editing.
		 */
		const handleActivityJoin = (data: CollabActivityData) => {
			if (!collabEditProvider) {
				return;
			}

			const remoteParticipants = collabEditProvider.getParticipants();
			if (getParticipantsCount() <= USER_LIMIT) {
				remoteParticipants.map((participant) => {
					if (participant.sessionId === data.sessionId) {
						const newParticipant = { ...participant, activity: data.activity };
						addOrUpdateSingleParticipant(newParticipant);
					}
				});
				collabEditProvider.sendMessage({
					type: 'activity:ack',
					activity: isEditPage ? 'EDITING' : 'VIEWING',
				});
			} else if (!isEditPage) {
				collabEditProvider.destroy();
			}
		};

		/**
		 * A Handler for event "presence" forwarded from NCS.
		 * This event "presence" is emitted whenever a participant joins or leaves a session. The event carries
		 * information of the lists of both join and left participants.
		 */
		const handlePresence = ({ joined, left }: CollabEventPresenceData) => {
			if (!collabEditProvider) {
				return;
			}

			const activity = isEditPage ? 'EDITING' : 'VIEWING';
			const currentUserSessionId = collabEditProvider.getSessionId();

			if (getParticipantsCount() <= USER_LIMIT) {
				joined?.forEach((joinedParticipant) => {
					let data: Participant = joinedParticipant;
					if (joinedParticipant.sessionId === currentUserSessionId) {
						data = { ...data, activity };
					}
					addOrUpdateSingleParticipant(data);
				});

				left?.forEach(({ sessionId: leftSessionId }) => {
					removeParticipant(leftSessionId);
				});

				if (
					joined?.find((joinedParticipant) => joinedParticipant.sessionId === currentUserSessionId)
				) {
					collabEditProvider.sendMessage({
						type: 'activity:join',
						activity: isEditPage ? 'EDITING' : 'VIEWING',
					});
				}
			} else if (!isEditPage) {
				collabEditProvider.destroy();
			}
		};

		// We are doing this because in the editor, there is no presence event
		if (isEditPage) {
			const currentUserSessionId = collabEditProvider?.getSessionId();
			const myParticipant = collabEditProvider
				.getParticipants()
				.find((participant) => participant.sessionId === currentUserSessionId);
			if (myParticipant) {
				if (getParticipantsCount() <= USER_LIMIT) {
					addOrUpdateSingleParticipant({ ...myParticipant, activity: 'EDITING' });
					collabEditProvider.sendMessage({
						type: 'activity:join',
						activity: 'EDITING',
					});
				}
			}
		}

		collabEditProvider.on('presence', handlePresence);
		collabEditProvider.on('activity:join', handleActivityJoin);
		collabEditProvider.on('activity:ack', handleActivityAck);
		return () => {
			collabEditProvider.off('presence', handlePresence);
			collabEditProvider.off('activity:join', handleActivityJoin);
			collabEditProvider.off('activity:ack', handleActivityAck);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [collabEditProvider, isEditPage]);

	useEffect(() => {
		const sessionId = collabEditProvider?.getSessionId();
		const uniqueParticipants = new Set();
		const avatarDescriptorsArray: Participant[] = [];
		// deduplicate participants
		currentParticipants?.forEach((p) => {
			const key = `${p.userId}-${p.activity}`;
			const existing = uniqueParticipants.has(key);
			if (!existing && p.activity) {
				uniqueParticipants.add(key);
				avatarDescriptorsArray.push(p);
			}
		});
		setAvatarDescriptors(
			avatarDescriptorsArray
				? avatarDescriptorsArray
						.sort((p) => (p.sessionId === sessionId ? -1 : 1))
						.map(generateAvatarDescriptorFromParticipant)
				: [],
		);
	}, [collabEditProvider, currentParticipants]);

	const isEmptyState = Boolean(avatarDescriptors && avatarDescriptors.length === 1);

	const PopupContent = isEmptyState ? (
		<TeammatePresenceNoCollaboratorsContainer sessionId={userSuggestionQuerySessionId} />
	) : (
		<TeammatePresenceMenu users={avatarDescriptors} />
	);

	const triggerId: string = isEmptyState
		? 'empty-teammate-presence-container'
		: 'teammate-presence-container';

	const avatarGroupProps: AvatarGroupProps = {
		testId: `${triggerId}-avatar-group`,
		data: avatarDescriptors,
		isTooltipDisabled: true,
		onMoreClick: () => {},
		appearance: 'stack',
		borderColor: token('color.border'),
		maxCount: MAX_AVATARS,
		overrides: {
			Avatar: {
				render: (Component, props) => {
					return (
						<Box xcss={isOpen && noPointerEvents}>
							<Tooltip content={getLabel(props as PresenceAvatarProps)}>
								<Component
									{...props}
									borderColor={getBorderColorFromSessionId(
										(props as PresenceAvatarProps).sessionId,
									)}
									label={getLabel(props as PresenceAvatarProps)}
								/>
							</Tooltip>
						</Box>
					);
				},
			},
		},
	};

	const getLabel = (user: PresenceAvatarProps) => {
		const { userId: participantUserId, activity, name } = user;
		if (participantUserId === userId) {
			return formatMessage(activity === 'EDITING' ? i18n.youAreEditing : i18n.youAreViewing);
		} else {
			return formatMessage(activity === 'EDITING' ? i18n.userIsEditing : i18n.userIsViewing, {
				name,
			});
		}
	};

	if (!collabEditProvider || (avatarDescriptors && avatarDescriptors.length === 0)) {
		return null;
	}

	return (
		<>
			<Popup
				isOpen={isOpen}
				onClose={() => setIsOpen(false)}
				placement="bottom-start"
				content={() => PopupContent}
				trigger={(triggerProps) => {
					return (
						<Flex xcss={imageContainerStyles} direction="row">
							<Pressable
								xcss={pressableStyles}
								testId={triggerId}
								onClick={() => setIsOpen(!isOpen)}
								{...triggerProps}
							>
								<div>
									<AvatarGroup {...avatarGroupProps} />
								</div>
							</Pressable>
							{!fg('confluence_frontend_unified_restrict_and_share') &&
								isEditPage &&
								avatarDescriptors.length < MAX_AVATARS && (
									<InviteToEditButton
										inviteToEditComponent={collabEditOption?.inviteToEditComponent}
									/>
								)}
						</Flex>
					);
				}}
			/>
			{!isEditPage && (
				<Flex alignItems="center">
					<Box xcss={dividerStyles} />
				</Flex>
			)}
		</>
	);
};
