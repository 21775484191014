import gql from 'graphql-tag';

export const CopyLinkQuery = gql`
	query CopyLinkQuery($contentId: ID!) {
		singleContent(id: $contentId) {
			id
			links {
				editui
				webui
				tinyui
				base
			}
		}
	}
`;
