import gql from 'graphql-tag';

export const RecentViewersQuery = gql`
	query RecentViewersQuery($contentId: ID!, $startTime: String!, $limit: Int!) {
		countGroupByUser(
			startTime: $startTime
			pageId: [$contentId]
			eventName: [page_viewed]
			limit: $limit
		) {
			nodes {
				count
				user {
					id
				}
			}
		}
	}
`;
