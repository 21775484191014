import {
	createStore,
	createActionsHook,
	createStateHook,
	createContainer,
	type ContainerComponent,
} from 'react-sweet-state';

import type { Activity, ProviderParticipant } from '@atlaskit/editor-common/collab';

type TeammatePresenceContainerProps = {
	contentId: string;
	contentType: string | undefined | null;
	spaceKey: string;
};

export type Participant = ProviderParticipant & {
	activity?: Activity;
};

type SessionId = string;

export type ParticipantsMap = Map<SessionId, Participant>;

const TeammatePresenceStore = createStore({
	initialState: {
		contentId: '',
		contentType: '',
		spaceKey: '',
		participantsMap: new Map<SessionId, Participant>(),
	},
	actions: {
		initializeStore:
			({ contentId, contentType, spaceKey }) =>
			({ setState }) => {
				setState({
					contentId,
					contentType,
					spaceKey,
					participantsMap: new Map<SessionId, Participant>(),
				});
			},
		updateParticipants:
			(newParticipantsMap: ParticipantsMap) =>
			({ setState }) => {
				setState({ participantsMap: newParticipantsMap });
			},
		addOrUpdateSingleParticipant:
			(participant: Participant) =>
			({ setState, getState }) => {
				const { participantsMap } = getState();
				if (participant.sessionId) {
					const updatedParticipants = new Map<SessionId, Participant>(participantsMap);
					updatedParticipants.set(participant.sessionId, participant);
					setState({ participantsMap: updatedParticipants });
				}
			},
		removeParticipant:
			(sessionId: string) =>
			({ setState, getState }) => {
				const { participantsMap } = getState();
				const updatedParticipants = new Map<SessionId, Participant>(participantsMap);
				updatedParticipants.delete(sessionId);
				setState({ participantsMap: updatedParticipants });
			},
		getParticipantsCount:
			() =>
			({ getState }) => {
				const { participantsMap } = getState();
				return participantsMap.size;
			},
	},
	name: 'teammatePresenceStore',
});

export const useTeammatePresenceState = createStateHook(TeammatePresenceStore);

export const useTeammatePresenceActions = createActionsHook(TeammatePresenceStore);

export const TeammatePresenceContainer: ContainerComponent<TeammatePresenceContainerProps> =
	createContainer(TeammatePresenceStore, {
		onInit:
			() =>
			({ setState }, { contentId, contentType, spaceKey }) => {
				setState({
					contentId,
					contentType: contentType ?? '',
					spaceKey,
					participantsMap: new Map<SessionId, Participant>(),
				});
			},
	});
