import React from 'react';

import type { UpgradeEditionButtonProps } from '../Upgrade/UpgradeEditionButton';
import { UpgradeEditionButton } from '../Upgrade/UpgradeEditionButton';

export const OnboardingSubtleUpgradeEditionButton = (
	props: Omit<UpgradeEditionButtonProps, 'appearance'>,
) => {
	return <UpgradeEditionButton appearance="subtle" {...props} />;
};
