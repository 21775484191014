import gql from 'graphql-tag';

export const ShareMutation = gql`
	mutation experimentalShareMutation($requestBody: ExperimentalShareRequestBodyType!)
	@experimental {
		experimentalShare(requestBody: $requestBody) {
			status
			success
		}
	}
`;
