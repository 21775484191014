import gql from 'graphql-tag';

export const FetchSuggestedUsersQuery = gql`
	query FetchSuggestedUsersQuery($cloudId: String!, $userId: String!, $sessionId: String!) {
		smarts {
			recommendedUser(
				recommendationsQuery: {
					context: { userId: $userId, tenantId: $cloudId }
					modelRequestParams: { caller: "confluence-page-presence", experience: "CgUserNearbyUser" }
					maxNumberOfResults: 10
					requestingUserId: $userId
					sessionId: $sessionId
				}
			) {
				id
				user {
					name
					accountId
					picture
					accountStatus
				}
			}
		}
	}
`;
