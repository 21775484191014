import React from 'react';

import { Box, Flex, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

const CircleBase = xcss({
	backgroundColor: 'color.background.accent.gray.subtlest',
	width: token('space.400'),
	height: token('space.400'),
	borderRadius: 'border.radius.circle',
});

export const EditorPresenceAvatarGroupLivePagePlaceholder = () => (
	<Flex testId="editor-presence-placeholder">
		<Box xcss={CircleBase} />
	</Flex>
);
