import React from 'react';

import { Box, Flex, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

const boxShadowStyles = {
	boxShadow: `0 0 0 2px ${token('elevation.surface.overlay')}`,
	zIndex: 1,
};

const marginStyles = {
	marginLeft: 'space.negative.050',
};
const CircleBase = xcss({
	backgroundColor: 'color.background.accent.gray.subtlest',
	width: token('space.400'),
	height: token('space.400'),
	borderRadius: 'border.radius.circle',
});

export const EditorPresenceAvatarGroupPlaceholder = () => (
	<Flex testId="editor-presence-placeholder">
		{/* eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop */}
		<Box xcss={CircleBase} style={boxShadowStyles} />
		{/* eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop */}
		<Box xcss={CircleBase} style={marginStyles} />
	</Flex>
);
