import memoizeOne from 'memoize-one';

import { avatarColors } from '@atlaskit/editor-shared-styles';
import type { ProviderParticipant } from '@atlaskit/editor-common/collab';

import type { PresenceAvatarProps } from './presenceTypes';
import type { Participant } from './useTeammatePresenceStore';
const hasSameParticipantBeenUsedBefore = (
	[a]: ProviderParticipant[],
	[b]: ProviderParticipant[],
) => {
	return a.name === b.name && a.avatar === b.avatar && a.sessionId === b.sessionId;
};

export const generateAvatarDescriptorFromParticipant = memoizeOne(
	(participant: Participant): PresenceAvatarProps => {
		return {
			userId: participant.userId,
			name: participant.name,
			src: participant.avatar,
			size: 'medium',
			sessionId: participant.sessionId,
			activity: participant.activity,
		};
	},
	hasSameParticipantBeenUsedBefore,
);

export const getBorderColorFromSessionId = (sessionId: String | undefined): string => {
	if (!sessionId) {
		return avatarColors[0];
	}

	/* eslint-disable no-bitwise */
	const deterministicHashFromSessionid = sessionId
		.split('')
		.reduce((hash, char) => ((hash << 5) - hash + char.charCodeAt(0)) | 0, 0);
	/* eslint-enable no-bitwise */
	const colorIndexFromHash = Math.abs(deterministicHashFromSessionid) % avatarColors.length;
	return avatarColors[colorIndexFromHash];
};
