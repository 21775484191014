import {
	ConfluenceDatabaseAri,
	ConfluencePageAri,
	ConfluenceBlogpostAri,
	ConfluenceWhiteboardAri,
} from '@atlassian/ari/confluence';

import { getConfluenceContentAri } from '@confluence/content-types-utils';

export type GetContentAriOptions = {
	activationId?: string;
	cloudId: string;
	contentId: string;
	contentType: string;
};

export const getContentAri = ({
	activationId,
	cloudId,
	contentId,
	contentType,
}: GetContentAriOptions) => {
	// Build ARI for embeds via `getConfluenceContentAri` until support is added to `@atlassian/ari`
	// TODO: Replace with `@atlassian/ari` https://hello.jira.atlassian.cloud/browse/CTIA-1835
	if (contentType === 'embed' && activationId) {
		return getConfluenceContentAri({
			siteId: cloudId,
			resourceType: contentType,
			activationId,
			resourceId: contentId,
		});
	}

	if (contentType === 'database' && activationId) {
		return ConfluenceDatabaseAri.create({
			siteId: cloudId,
			databaseId: contentId,
			activationId,
		}).toString();
	}

	let ari: ConfluencePageAri | ConfluenceWhiteboardAri | ConfluenceBlogpostAri | null = null;
	// A space ari should not have spacekey as it is UGC (HOT-96075)
	// A space should have a page based ARI as only then it will generate the
	// notification that will be propagated to mobile as well and will be consistent with Mobile.
	// So a space will also have page based ARI. (Discussed in HOT-96778)
	switch (contentType) {
		case 'blogpost':
			ari = ConfluenceBlogpostAri.create({
				siteId: cloudId,
				blogpostId: contentId,
			});
			break;
		case 'whiteboard':
			ari = ConfluenceWhiteboardAri.create({
				siteId: cloudId,
				whiteboardId: contentId,
			});
			break;
		default:
			ari = ConfluencePageAri.create({
				siteId: cloudId,
				pageId: contentId,
			});
			break;
	}

	return ari.toString();
};

export const getContentTypeForShare = (routeName?: string) => {
	// This function deliberately doesn't know about new content types. It's a fallback
	// for deriving content type from a URL, but for new types, it's better to
	// explicitly pass a `shareContentType` and `shareFormTitle` to the `<ShareButton>` if possible.
	if (!routeName) return 'page';
	// either page, or blog
	if (routeName.includes('BLOG')) return 'blogpost';
	return 'page';
};

export const isSlackIntegrationSupported = (contentType?: string) => {
	return contentType !== 'embed' && contentType !== 'database';
};
