import { useCallback, useContext } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';
import { useQuery, useMutation } from '@apollo/react-hooks';
import OriginTracer from '@atlassiansox/origin-tracing';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { markErrorAsHandled } from '@confluence/graphql';
import { usePageSpaceKey, useContentType } from '@confluence/page-context';
import type { RoutesContextType } from '@confluence/route-manager';
import { RoutesContext, getSingleParam, addQueryParams } from '@confluence/route-manager';
import { VIEW_BLOG, VIEW_PAGE, isEditPage } from '@confluence/named-routes';
import { useSessionData } from '@confluence/session-data';

import type {
	ShareToEntitiesContentQuery as ShareToEntitiesContentQueryData,
	ShareToEntitiesContentQueryVariables,
} from './__types__/ShareToEntitiesContentQuery';
import type {
	experimentalShareMutationVariables as ShareMutationVariables,
	experimentalShareMutation as ShareMutationResponse,
	ExperimentalShareRequestBodyType as ShareRequestBodyType,
} from './__types__/ShareMutation';
import { ShareToEntitiesContentQuery } from './ShareToEntitiesContentQuery.graphql';
import { ShareMutation } from './ShareMutation.experimentalgraphql';
import { getContentAri } from './contentTypeHelpers';
import { getViewContentShareUrl } from './urlHelpers';
import { useCopyContentLink } from './copy-link/useCopyContentLink';
import type { PAGE_MODE } from './copy-link/CopyLink';

const ANALYTICS_SOURCE = 'useShareToEntities';

const i18n = defineMessages({
	emptyTitleDefault: {
		id: 'share.default.title',
		description: 'Default share title for content that is untitled',
		defaultMessage: 'Untitled',
	},
});

export const getContentShareLink = ({
	routesContext,
	contentType,
	contentId,
	spaceKey,
	isEditMode,
	getCopyContentLink,
}: {
	routesContext: RoutesContextType;
	contentType: string;
	contentId: string;
	spaceKey?: string;
	isEditMode?: boolean;
	getCopyContentLink: () => string | undefined;
}): string => {
	/**
	 * Should always use the refactor version if isEditMode = true (which is only for presence feature).
	 * If `getCopyContentLink` returns undefined, use the original code
	 */
	let contentShareLink = getCopyContentLink();
	if (isEditMode && contentShareLink) {
		return contentShareLink;
	}

	// ShareButton constructs the URL to send based on routes context (current
	// location in Confluence app). We are matching ShareButton logic as closely
	// as we can to provide a consistent experience, but given that we could
	// embed Share anywhere in the app, we should consider constructing URLs
	// based on a combination of content data from the BE (eg fetch content
	// type, status etc) + consumer specifications (give me a view vs edit URL)
	// and maintain an explicit set of link generation rules in this package.
	const namedRoute = routesContext.match;
	const draftShareId = getSingleParam(routesContext.getQueryParams(), 'draftShareId');
	// If user is coming from edit page/blog, we want to send a "view content"
	// link, NOT the current edit URL. (Drafts are an exception; they have
	// no valid view page URL so we must construct a separate link.)
	if (namedRoute && isEditPage(String(namedRoute)) && !draftShareId) {
		const spaceKeyParam = spaceKey ?? namedRoute?.params.spaceKey;
		return contentType === 'blog'
			? new URL(
					VIEW_BLOG.toUrl({ spaceKey: spaceKeyParam, contentId }),
					document.baseURI,
				).toString()
			: new URL(
					VIEW_PAGE.toUrl({ spaceKey: spaceKeyParam, contentId }),
					document.baseURI,
				).toString();
	}

	// At this point we know we want to share content in the VIEW mode + double checks that getCopyContentLink didn't return undefined
	if (contentShareLink) {
		return contentShareLink;
	}

	contentShareLink = getViewContentShareUrl();
	if (draftShareId !== undefined) {
		contentShareLink = addQueryParams(contentShareLink, { draftShareId });
	}
	return contentShareLink;
};

type UserOrGroupEntity = {
	id: string;
	type: string;
};

export type ShareResponse = {
	success: boolean;
	error?: string;
};

type ShareToEntitiesValue = {
	shareToEntities: (
		newEntities: UserOrGroupEntity[],
		isEditMode?: boolean,
	) => Promise<ShareResponse>;
};

const SUPPORTED_CONTENT_TYPES = ['page', 'blogpost', 'whiteboard'];

export const useShareToEntities = (
	contentId: string,
	analyticsSource?: string,
	contentMode?: PAGE_MODE,
): ShareToEntitiesValue => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const intl = useIntl();

	const { activationId, cloudId } = useSessionData();
	// Query BE as source of truth for content type, but we can also derive it from
	// page context (routes-based) as a fallback
	const [contentTypeFromPageContext] = useContentType();
	const [spaceKey] = usePageSpaceKey();
	const { getCopyContentLink } = useCopyContentLink({
		pageMode: contentMode,
		contentType: contentTypeFromPageContext ?? '',
		contentId,
		spaceKey: spaceKey!,
		source: 'useShareToEntities',
	});
	const { data, error } = useQuery<
		ShareToEntitiesContentQueryData,
		ShareToEntitiesContentQueryVariables
	>(ShareToEntitiesContentQuery, {
		variables: {
			contentId,
		},
	});
	// Swallow the error; not having the data will be
	// fairly inconsequential for the user experience
	error && markErrorAsHandled(error);

	const handleShareComplete = (result) => {
		createAnalyticsEvent({
			type: 'sendOperationalEvent',
			data: {
				source: analyticsSource ?? ANALYTICS_SOURCE,
				actionSubject: 'notify',
				action: 'completed',
				attributes: {
					contentId,
					success: Boolean(result?.experimentalShare?.success),
					status: result?.experimentalShare?.status,
				},
			},
		}).fire();
	};

	const [share] = useMutation<ShareMutationResponse, ShareMutationVariables>(ShareMutation, {
		onCompleted: handleShareComplete,
	});

	const routesContext = useContext(RoutesContext);

	const contentType = data?.singleContent?.type ?? contentTypeFromPageContext ?? '';
	const contentTitle = data?.singleContent?.title;

	const originTracingFactory = useCallback(() => new OriginTracer({ product: 'confluence' }), []);

	return {
		shareToEntities: async (entities: UserOrGroupEntity[], isEditMode?: boolean) => {
			if (!SUPPORTED_CONTENT_TYPES.includes(contentType)) {
				throw new Error('Not a supported content type');
			}
			if (!contentId) {
				throw new Error('No content ID');
			}

			const entityList: UserOrGroupEntity[] = entities.map((entity) => ({
				id: entity.id,
				type: entity.type,
			}));

			const requestBody: ShareRequestBodyType = {
				content: {
					link: getContentShareLink({
						routesContext,
						contentType,
						contentId,
						spaceKey,
						isEditMode,
						getCopyContentLink,
					}),
					ari: getContentAri({ activationId, contentId, cloudId, contentType }),
					title: contentTitle || intl.formatMessage(i18n.emptyTitleDefault),
					type: contentType,
				},
				recipients: entityList,
				metadata: {
					productId: 'confluence',
					atlOriginId: String(originTracingFactory().id),
					// even if we are in edit mode we want to share the "view" page URL, not edit
					shareeAction: isEditMode ? 'edit' : 'view',
				},
			};

			const result = await share({ variables: { requestBody } });
			return {
				success: Boolean(result?.data?.experimentalShare?.success),
			};
		},
	};
};
