import React, { useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { getAGGClient } from '@confluence/graphql';
import { useSessionData } from '@confluence/session-data';
import { ErrorDisplay } from '@confluence/error-boundary';

import { TeammatePresenceNoCollaboratorsPopup } from './TeammatePresenceNoCollaboratorsPopup';
import { TeammatePresenceSuggestionsMenu } from './suggestions/TeammatePresenceSuggestionsMenu';
import { FetchSuggestedUsersQuery } from './SuggestedCollaboratorsQuery.aggraphql';
import { transformSuggestedUserResponse } from './transformSuggestedUserResponse';

export type SuggestedUser = {
	id: string;
	name: string;
	url: string;
};

type TeammatePresenceNoCollaboratorsContainerType = {
	sessionId: string;
};

export const TeammatePresenceNoCollaboratorsContainer = ({
	sessionId,
}: TeammatePresenceNoCollaboratorsContainerType) => {
	const { cloudId, userId } = useSessionData();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { data, loading, error } = useQuery(FetchSuggestedUsersQuery, {
		client: getAGGClient(),
		variables: {
			cloudId,
			userId,
			sessionId,
		},
		skip: !userId || !cloudId,
	});

	const suggestedUsers: SuggestedUser[] = transformSuggestedUserResponse({
		userList: data?.smarts?.recommendedUser || [],
		currentUserId: userId,
	});

	useEffect(() => {
		if (error) {
			createAnalyticsEvent({
				type: 'sendOperationalEvent',
				data: {
					action: 'errored',
					actionSubject: 'collaborationGraphSuggestedUsersQuery',
					source: 'presencePopup',
					attributes: {
						error,
					},
				},
			}).fire();
		}
	}, [createAnalyticsEvent, error]);

	if (loading) {
		return null;
	}

	return (
		<>
			{error && <ErrorDisplay error={error} />}
			{suggestedUsers.length ? ( // Identity can throw an error, but still return data
				<TeammatePresenceSuggestionsMenu suggestedUsers={suggestedUsers} />
			) : (
				<TeammatePresenceNoCollaboratorsPopup />
			)}
		</>
	);
};
