import { removeEverythingAfterContentSlug } from '@confluence/route-manager';

export const safeJSONParse = (json) => {
	try {
		return JSON.parse(json);
	} catch (e) {}
	return null;
};

export const getDraftShareId = () => {
	const match = window.location.search.match(/draftShareId=([^&#]*)/);
	return (match && match[1]) || undefined;
};

export const getLinkType = (shareeAction) => {
	// eslint-disable-next-line no-restricted-syntax
	const str = window.location.href;
	if (/\/wiki\/spaces\/.*\/history\//.test(str)) {
		return 'history';
	}
	if (shareeAction === 'edit') {
		return 'edit';
	}
	return undefined;
};

export const getViewContentShareUrl = () => {
	// Drop non-required params from the URL so we are not sharing UGC or
	// sending a link focused on comments or anchors
	return (
		// eslint-disable-next-line no-restricted-syntax
		window?.location?.href &&
		// eslint-disable-next-line no-restricted-syntax
		removeEverythingAfterContentSlug(window.location.href)
	);
};
