import React, { useEffect } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl-next';

import { Box, Stack, xcss } from '@atlaskit/primitives';
import { MenuGroup, Section } from '@atlaskit/menu';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { useIsEditorPage } from '@confluence/route-manager/entry-points/useIsEditorPage';
import { useShareToEntities } from '@confluence/share/entry-points/useShareToEntities';
import { PAGE_MODE } from '@confluence/share/entry-points/useCopyContentLink';

import type { SuggestedUser } from '../TeammatePresenceNoCollaboratorsContainer';
import { useTeammatePresenceState } from '../useTeammatePresenceStore';

import { TeammatePresenceSuggestionsSectionRow } from './TeammatePresenceSuggestionsSectionItem';

const i18n = defineMessages({
	viewingDescription: {
		id: 'experiment-teammate-presence.suggestions-menu.viewing.title',
		defaultMessage:
			'You’re the only one viewing this page. Share it with others who might be interested.',
		description:
			'The text is shown as a description of a section inside of a menu that appears in the view page mode. It describes the purpose of the menu, where sharing invites a user to the current page',
	},
	editingDescription: {
		id: 'experiment-teammate-presence.suggestions-menu.editing.title',
		defaultMessage: 'You’re the only one editing this page. Invite someone to join you.',
		description:
			'The text is shown as a description of a section inside of a menu that appears in the editor. It describes the purpose of the menu where there are buttons inviting someone to the current page.',
	},
	suggestionsSectionTitle: {
		id: 'experiment-teammate-presence.suggestions-menu.suggestions-section-title',
		defaultMessage: 'Suggestions based on people you work with',
		description: 'The suggestions section title',
	},
});

const containerStyles = xcss({
	backgroundColor: 'elevation.surface.overlay',
	boxShadow: 'elevation.shadow.overlay',
	borderRadius: 'border.radius',
	paddingTop: 'space.075',
});

const descriptionStyles = xcss({
	paddingTop: 'space.100',
	paddingBottom: 'space.100',
	paddingLeft: 'space.200',
	paddingRight: 'space.200',
});

type TeammatePresenceSuggestionsMenuProps = {
	suggestedUsers: SuggestedUser[];
};

export const TeammatePresenceSuggestionsMenu = ({
	suggestedUsers,
}: TeammatePresenceSuggestionsMenuProps) => {
	const isEditPage = useIsEditorPage();
	const { contentId } = useTeammatePresenceState();
	const { formatMessage } = useIntl();
	const { shareToEntities } = useShareToEntities(
		contentId,
		'presenceSuggestionsMenu',
		isEditPage ? PAGE_MODE.EDIT : PAGE_MODE.VIEW,
	);
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const message = isEditPage ? i18n.editingDescription : i18n.viewingDescription;

	useEffect(() => {
		createAnalyticsEvent({
			type: 'sendScreenEvent',
			data: {
				name: 'presenceSuggestionsMenu',
				attributes: {
					isEditPage,
				},
			},
		}).fire();
	}, [createAnalyticsEvent, isEditPage]);

	return (
		<Box xcss={containerStyles}>
			<MenuGroup minWidth={360} maxWidth={360}>
				<Stack space="space.150" xcss={descriptionStyles}>
					<FormattedMessage {...message} />
				</Stack>
				<Section title={formatMessage(i18n.suggestionsSectionTitle)}>
					{suggestedUsers.map((user, index) => {
						const handleShare = () => {
							void shareToEntities([{ id: user.id, type: 'user' }], isEditPage);
						};

						return (
							<TeammatePresenceSuggestionsSectionRow
								key={`section-row-${index}`}
								name={user.name}
								userId={user.id}
								handleShare={handleShare}
								url={user.url}
							/>
						);
					})}
				</Section>
			</MenuGroup>
		</Box>
	);
};
