interface CollaborationGraphGQLUserFormat {
	accountId: string;
	name: string;
	picture: string;
	accountStatus: string;
	__typename: string;
}

export interface CollaborationGraphGQLDataFormat {
	id: string;
	user: CollaborationGraphGQLUserFormat;
}

export const transformSuggestedUserResponse = ({
	userList,
	currentUserId,
}: {
	userList: CollaborationGraphGQLDataFormat[];
	currentUserId: string | null;
}) => {
	return userList
		.filter((data: CollaborationGraphGQLDataFormat) => {
			const { user } = data;
			if (!user) {
				return false;
			}

			return (
				user.accountId !== currentUserId &&
				user.__typename !== 'AppUser' &&
				user.accountStatus === 'active'
			);
		})
		.slice(0, 5)
		.map(({ user }) => {
			return {
				id: user.accountId,
				name: user.name,
				url: user.picture,
			};
		});
};
