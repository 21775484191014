const isClipboardApiSupported = (): boolean =>
	navigator.clipboard && typeof navigator.clipboard.writeText === 'function';

export const useCopyToClipboard = () => {
	const copyToClipboard = async (value): Promise<void> => {
		if (isClipboardApiSupported()) {
			await navigator.clipboard.writeText(value);
		}
	};

	return { copyToClipboard };
};
