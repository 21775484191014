/** @jsx jsx */
import type { ReactNode } from 'react';
import React, { Fragment } from 'react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { jsx } from '@emotion/react';
import { defineMessages, useIntl } from 'react-intl-next';
import { styled } from '@compiled/react';

import InviteTeamIcon from '@atlaskit/icon/glyph/editor/add';
import Button from '@atlaskit/button/standard-button';
import Tooltip from '@atlaskit/tooltip';
import type { InviteToEditComponentProps } from '@atlaskit/editor-common/collab';
import { N20 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const InviteToEditWrapper = styled.div({
	background: token('color.background.neutral', N20),
	borderRadius: '50%',
	minWidth: token('space.400', '32px'),
	marginLeft: token('space.negative.050', '-4px'),

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'.invite-to-edit': {
		borderRadius: '50%',
	},
});

const i18n = defineMessages({
	inviteToEditTitle: {
		id: 'editor-presence-avatar-group.invite-to-edit.title',
		defaultMessage: 'Invite to edit',
		description: 'Invite another user to edit the current document',
	},
});

const PlainWrapper = ({ children }: { children: ReactNode }) => <Fragment>{children}</Fragment>;

export interface InviteToEditButtonProps {
	inviteToEditComponent?: React.ComponentType<InviteToEditComponentProps>;
}

export const InviteToEditButton = ({ inviteToEditComponent }: InviteToEditButtonProps) => {
	const intl = useIntl();

	if (!inviteToEditComponent) {
		return null;
	}

	const Wrapper = inviteToEditComponent || PlainWrapper;
	const title = intl.formatMessage(i18n.inviteToEditTitle);

	return (
		<InviteToEditWrapper>
			<Tooltip content={title} hideTooltipOnClick position="bottom">
				<Wrapper>
					<Button
						// eslint-disable-next-line @atlaskit/design-system/no-unsafe-style-overrides, @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
						className="invite-to-edit"
						testId="invite-to-edit-button"
						appearance="subtle"
						iconBefore={<InviteTeamIcon label={title} />}
					/>
				</Wrapper>
			</Tooltip>
		</InviteToEditWrapper>
	);
};
