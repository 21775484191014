import gql from 'graphql-tag';

export const ShareToEntitiesContentQuery = gql`
	query ShareToEntitiesContentQuery($contentId: ID!) {
		singleContent(id: $contentId) {
			title
			type
			id
		}
	}
`;
